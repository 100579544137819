<template>
  <div class="dash-board-wrap">
    <div class="top-wrap d-flex align-center justify-space-between mb-4">
      <div class="notice-board d-flex align-center">
        <v-tooltip top color="black">
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              icon
              color="warning"
              link
              to="/boards-0"
            >
              <v-icon>mdi-bullhorn</v-icon>
            </v-btn>
          </template>
          <span>공지사항 바로가기</span>
        </v-tooltip>
        <div class="d-flex justyfy-center pl-10 pr-12" v-if="noticePosts === null">
          <v-progress-circular
            indeterminate
            :size="20"
            color="warning"
          />
        </div>
        <div class="d-flex align-center" v-else-if="noticePosts.length >= 1">
          <v-tooltip top color="black">
            <template #activator="{ on }">
              <p class="mb-0 ml-2 main" v-on="on" @click="noticeView(noticePosts[0].no)">
                {{ noticePosts[0].title }}
              </p>
            </template>
            <span>열람</span>
          </v-tooltip>
          <p class="mb-0 ml-12 creator d-flex align-center">
            <v-chip
              class="pl-1 pr-1"
              small label outlined
            >
              {{ comm.getGroupFullPath(groups, noticePosts[0].creatorGroupNo, '그룹없음') }}
            </v-chip>
            <span class="ml-1">{{ noticePosts[0].creatorName }}</span>
          </p>
          <p class="mb-0 ml-4 mr-2 gray--text date">
            {{ time.makeLocalTime(noticePosts[0].created, 'min') }}
          </p>
        </div>
        <div class="empty-text gray--text pl-2 pr-2" v-else-if="noticePosts.length === 0">
          공지사항이 없습니다.
        </div>
      </div>
      <v-tooltip top color="black">
        <template #activator="{ on }">
          <v-btn
            icon class="mt-1 ml-1"
            v-on="on"
            @click="getDatas()"
            :loading="loading"
            color="primary"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>대시보드 새로고침</span>
      </v-tooltip>
    </div>
    <div class="chart-row d-flex align-start">
      <div class="chart-box w-50">
        <div class="chart-title">
          개인 매출 TOP 10
          <div class="d-flex align-center">
            <v-chip v-for="(row, index) in rankDates" :key="index"
              small class="ml-2 pl-2 pr-2"
              :color="colorSchema.manager" link
              :outlined="dateType.manager !== index"
              @click="
                dateType.manager = index;
                getRankChartData('manager');
              "
            >
              {{
                index === 0 ? '최근 1년' : time.moment(row.start).format('YYYY년 M월')
              }}
            </v-chip>
          </div>
        </div>
        <div
          class="d-flex align-center justify-center pa-10 pa-14 mb-5"
          v-if="managerData[0].data === null"
        >
          <v-progress-circular
            indeterminate
            :color="colorSchema.manager"
            :size="25"
            :width="5"
          ></v-progress-circular>
        </div>
        <div
          class="empty-chart py-10 px-6 mt-4 mb-5"
          v-else-if="managerData[0].data.length <= 0"
        >
          데이터가 없습니다.
        </div>
        <vue-apex-charts
          v-else
          height="400px"
          type="bar"
          :options="managerOptions"
          :series="managerData"
        />
      </div>
      <div class="chart-box w-50">
        <div class="chart-title">
          그룹 매출 TOP 10
          <div class="d-flex align-center">
            <v-chip v-for="(row, index) in rankDates" :key="index"
              small class="ml-2 pl-2 pr-2"
              :color="colorSchema.group" link
              :outlined="dateType.group !== index"
              @click="
                dateType.group = index;
                getRankChartData('group');
              "
            >
              {{
                index === 0 ? '최근 1년' : time.moment(row.start).format('YYYY년 M월')
              }}
            </v-chip>
          </div>
        </div>
        <div
          class="d-flex align-center justify-center pa-10 pa-14 mb-5"
          v-if="groupData[0].data === null"
        >
          <v-progress-circular
            indeterminate
            :color="colorSchema.group"
            :size="25"
            :width="5"
          ></v-progress-circular>
        </div>
        <div
          class="empty-chart py-10 px-6 mt-4 mb-5"
          v-else-if="groupData[0].data.length <= 0"
        >
          데이터가 없습니다.
        </div>
        <vue-apex-charts
          v-else
          height="400px"
          type="bar"
          :options="groupOptions"
          :series="groupData"
        />
      </div>
    </div>
    <div class="chart-row d-flex align-start">
      <div class="chart-box w-50">
        <div class="chart-title">
          개인 매출(코인) TOP 10
          <div class="d-flex align-center">
            <v-chip v-for="(row, index) in rankDates" :key="index"
              small class="ml-2 pl-2 pr-2"
              :color="colorSchema.managerCoin" link
              :outlined="dateType.managerCoin !== index"
              @click="
                dateType.managerCoin = index;
                getRankChartData('managerCoin');
              "
            >
              {{
                index === 0 ? '최근 1년' : time.moment(row.start).format('YYYY년 M월')
              }}
            </v-chip>
          </div>
        </div>
        <div
          class="d-flex align-center justify-center pa-10 pa-14 mb-5"
          v-if="managerCoinData[0].data === null"
        >
          <v-progress-circular
            indeterminate
            :color="colorSchema.managerCoin"
            :size="25"
            :width="5"
          ></v-progress-circular>
        </div>
        <div
          class="empty-chart py-10 px-6 mt-4 mb-5"
          v-else-if="managerCoinData[0].data.length <= 0"
        >
          데이터가 없습니다.
        </div>
        <vue-apex-charts
          v-else
          height="400px"
          type="bar"
          :options="managerCoinOptions"
          :series="managerCoinData"
        />
      </div>
      <div class="chart-box w-50">
        <div class="chart-title">
          그룹 매출(코인) TOP 10
          <div class="d-flex align-center">
            <v-chip v-for="(row, index) in rankDates" :key="index"
              small class="ml-2 pl-2 pr-2"
              :color="colorSchema.groupCoin" link
              :outlined="dateType.groupCoin !== index"
              @click="
                dateType.groupCoin = index;
                getRankChartData('groupCoin');
              "
            >
              {{
                index === 0 ? '최근 1년' : time.moment(row.start).format('YYYY년 M월')
              }}
            </v-chip>
          </div>
        </div>
        <div
          class="d-flex align-center justify-center pa-10 pa-14 mb-5"
          v-if="groupCoinData[0].data === null"
        >
          <v-progress-circular
            indeterminate
            :color="colorSchema.groupCoin"
            :size="25"
            :width="5"
          ></v-progress-circular>
        </div>
        <div
          class="empty-chart py-10 px-6 mt-4 mb-5"
          v-else-if="groupCoinData[0].data.length <= 0"
        >
          데이터가 없습니다.
        </div>
        <vue-apex-charts
          v-else
          height="400px"
          type="bar"
          :options="groupCoinOptions"
          :series="groupCoinData"
        />
      </div>
    </div>
    <div class="chart-row d-flex align-start">
      <div class="chart-box w-100">
        <div class="chart-title">
          월별 매출/환불
          <div class="d-flex align-center">
            <v-chip v-for="(row, index) in monthDates" :key="index"
              small class="ml-2 pl-2 pr-2"
              :color="colorSchema.month" link
              :outlined="dateType.month !== index"
              @click="
                dateType.month = index;
                getMonthChartData('month');
              "
            >
              {{ row.name }}
            </v-chip>
          </div>
        </div>
        <div
          class="d-flex align-center justify-center pa-10 pa-14 mb-5"
          v-if="monthData[0].data === null"
        >
          <v-progress-circular
            indeterminate
            :color="colorSchema.month"
            :size="25"
            :width="5"
          ></v-progress-circular>
        </div>
        <div
          class="empty-chart py-10 px-6 mt-4 mb-5"
          v-else-if="monthData[0].data.length <= 0"
        >
          데이터가 없습니다.
        </div>
        <vue-apex-charts
          v-else
          height="400px"
          type="line"
          :options="monthOptions"
          :series="monthData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import comm from '@/util/comm';
import time from '@/util/time';

export default {
  name: 'DashBoard',
  components: {
    VueApexCharts,
  },
  data: () => ({
    comm,
    time,
    groups: [],
    loading: false,
    noticePosts: null,
    colorSchema: {
      manager: 'info',
      group: 'primary',
      managerCoin: 'info',
      groupCoin: 'primary',
      sale: 'success',
      refund: 'error',
      month: 'success',
    },
    dateType: {
      manager: 3,
      group: 3,
      managerCoin: 3,
      groupCoin: 3,
      sale: 2,
      refund: 2,
      month: 2,
    },
    rankDates: [
      {
        start: time.moment().add(-1, 'Y').format('YYYY-MM-DD'),
        end: time.moment().format('YYYY-MM-DD'),
      },
      {
        start: time.moment().add(-2, 'M').startOf('M').format('YYYY-MM-DD'),
        end: time.moment().add(-2, 'M').endOf('M').format('YYYY-MM-DD'),
      },
      {
        start: time.moment().add(-1, 'M').startOf('M').format('YYYY-MM-DD'),
        end: time.moment().add(-1, 'M').endOf('M').format('YYYY-MM-DD'),
      },
      {
        start: time.moment().startOf('M').format('YYYY-MM-DD'),
        end: time.moment().endOf('M').format('YYYY-MM-DD'),
      },
    ],
    rankColors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7'],
    rankOptionsForm: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: (val) => comm.priceToString(val),
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      colors: [],
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        background: {
          enabled: true,
          borderWidth: 1,
          borderColor: '#ccc',
          foreColor: '#222',
          opacity: 1,
        },
        formatter: (val, opt) => `${opt.w.globals.labels[opt.dataPointIndex]}`,
        offsetX: 0,
      },
      tooltip: {
        y: {
          title: {
            formatter: () => '',
          },
          formatter: (val) => comm.priceToString(val),
        },
      },
    },
    managerOptions: null,
    managerData: [{
      data: null,
    }],
    groupOptions: null,
    groupData: [{
      data: null,
    }],
    managerCoinOptions: null,
    managerCoinData: [{
      data: null,
    }],
    groupCoinOptions: null,
    groupCoinData: [{
      data: null,
    }],
    api_text: '',
    monthDates: [
      {
        name: '최근 3년',
        start: time.moment().add(-2, 'Y').startOf('M').format('YYYY-MM-DD'),
        end: time.moment().format('YYYY-MM-DD'),
      },
      {
        name: '최근 1년',
        start: time.moment().add(-1, 'Y').startOf('M').format('YYYY-MM-DD'),
        end: time.moment().format('YYYY-MM-DD'),
      },
      {
        name: '최근 6개월',
        start: time.moment().add(-5, 'M').startOf('M').format('YYYY-MM-DD'),
        end: time.moment().format('YYYY-MM-DD'),
      },
    ],
    monthOptionsForm: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: (val) => time.moment(val).format('YYYY년 M월'),
        },
      },
      yaxis: {
        labels: {
          formatter: (val) => comm.priceToString(val),
        },
      },
      tooltip: {
        y: {
          title: {
            formatter: () => '',
          },
          formatter: (val) => comm.priceToString(val),
        },
      },
      colors: undefined,
    },
    saleOptions: null,
    saleData: [{
      data: null,
    }],
    refundOptions: null,
    refundData: [{
      data: null,
    }],
    monthOptions: null,
    monthData: [
      {
        name: '매출',
        data: null,
      },
      {
        name: '환불',
        data: null,
      },
      {
        name: '레퍼럴',
        data: null,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      post: 'dialog/post',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    getDatas() {
      this.loading = true;
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
          this.getNoticePosts();
          this.getRankChartData('manager');
          this.getRankChartData('group');
          this.getRankChartData('managerCoin');
          this.getRankChartData('groupCoin');
          this.getMonthChartData('month');
          this.loading = false;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    getNoticePosts() {
      this.noticePosts = null;
      this.$socket.emit('board.post.list.get', {
        boardNo: 0,
        page: 1,
        itemsPerPage: 1,
        sortBy: ['created'],
        sortDesc: [true],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.noticePosts = resp.items;
        } else {
          this.noticePosts = [];
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    noticeView(no) {
      this.progress(true);
      this.$socket.emit('board.post.get', { no }, (resp) => {
        if (resp.result === 'success') {
          this.post({
            show: true,
            type: 1,
            func: () => {
              this.getDatas();
            },
            item: { ...resp.item },
          });
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    updateDate() {
      const start = this.dates.sort()[0];
      const end = this.dates.sort()[1];
      const { target } = this.datePicker;
      this[`${target}Dates`].start = start;
      this[`${target}Dates`].end = end;
      this.datePicker.id = null;
      this.dates = [];
      if (target === 'manager' || target === 'group') {
        this.getRankChartData({ column: target, dates: this[`${target}Dates`] });
      }
    },
    getRankChartData(column) {
      let options = {};
      this[`${column}Data`][0].data = null;
      this[`${column}Options`] = null;
      this.rankOptionsForm.xaxis.categories = [];
      this.api_text = '';
      if (column === 'manager' || column === 'group') {
        options = {
          page: 1,
          itemsPerPage: 0,
          column,
          filters: [
            {
              condition: 'ge',
              column: 'date',
              value: time.makeUTCDate(this.rankDates[this.dateType[column]].start),
            },
            {
              where: 'and',
              condition: 'le',
              column: 'date',
              value: `${time.makeUTCDate(this.rankDates[this.dateType[column]].end)} 23:59:59`,
            },
            {
              where: 'and',
              condition: 'eq',
              column: 'type',
              value: 0,
            },
            {
              where: 'and',
              condition: 'eq',
              column: 'status',
              value: 1,
            },
            {
              where: 'and',
              condition: 'gt',
              column: 'amount',
              value: 0,
            },
          ],
        };
        this.api_text = 'sales.rank.chart.get';
      } else {
        options = {
          page: 1,
          itemsPerPage: 0,
          column,
          filters: [
            {
              condition: 'ge',
              column: 'date',
              value: time.makeUTCDate(this.rankDates[this.dateType[column]].start),
            },
            {
              where: 'and',
              condition: 'le',
              column: 'date',
              value: `${time.makeUTCDate(this.rankDates[this.dateType[column]].end)} 23:59:59`,
            },
          ],
        };
        this.api_text = 'coin.rank.chart.get';
      }
      this.$socket.emit(this.api_text, options, (resp) => {
        if (resp.result === 'success') {
          if (resp.item.length > 0) {
            const datasets = resp.item.reduce((obj, row) => {
              if (column === 'manager' || column === 'managerCoin') {
                obj.categories.push(`${comm.getGroupFullPath(this.groups, row.managerGroupNo, '그룹없음 ')}${row.managerName}`);
                obj.colors.push(this.rankColors[row.managerGroupNo % this.rankColors.length]);
              } else if (column === 'group' || column === 'groupCoin') {
                if (row.group_no === undefined) {
                  obj.categories.push(`${comm.getGroupFullPath(this.groups, row.group, '그룹없음')}`);
                  obj.colors.push(this.rankColors[row.group % this.rankColors.length]);
                } else {
                  obj.categories.push(`${comm.getGroupFullPath(this.groups, row.group_no, '그룹없음')}`);
                  obj.colors.push(this.rankColors[row.group_no % this.rankColors.length]);
                }
              }
              obj.data.push(Number(row.amount));
              return obj;
            }, {
              data: [],
              colors: [],
              categories: [],
            });
            this[`${column}Data`][0].data = datasets.data;
            this.rankOptionsForm.xaxis.categories = datasets.categories;
            this.rankOptionsForm.colors = datasets.colors;
            this[`${column}Options`] = { ...this.rankOptionsForm };
          } else {
            this[`${column}Data`][0].data = [];
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          this[`${column}Data`][0].data = [];
          console.error(resp);
        }
      });
    },
    getMonthChartData(column) {
      this[`${column}Data`][0].data = null;
      this[`${column}Data`][1].data = null;
      this[`${column}Data`][2].data = null;
      this[`${column}Options`] = null;
      this.monthOptionsForm.xaxis.categories = [];
      this.$socket.emit('sales.month.chart.get', {
        page: 1,
        itemsPerPage: 0,
        filters: [
          {
            condition: 'ge',
            column: 'date',
            value: time.makeUTCDate(this.monthDates[this.dateType[column]].start),
          },
          {
            where: 'and',
            condition: 'le',
            column: 'date',
            value: `${time.makeUTCDate(this.monthDates[this.dateType[column]].end)} 23:59:59`,
          },
          {
            where: 'and',
            condition: 'eq',
            column: 'status',
            value: 1,
          },
        ],
      }, (resp) => {
        if (resp.result === 'success') {
          if (resp.item.length > 0) {
            const { start, end } = this.monthDates[this.dateType[column]];
            const diff = time.moment(end).diff(time.moment(start), 'M');
            const datasets = {
              categories: [],
              saleData: [],
              refundData: [],
              referralData: [],
            };
            for (let i = 0; i <= diff; i += 1) {
              const categories = time.moment(start).add(i, 'M').format('YYYY-MM');
              const target = resp.item.find((r) => r.month === categories);
              datasets.categories.push(time.moment(start).add(i, 'M').format('YYYY-MM'));
              datasets.saleData.push(
                target?.saleAmount != null ? Number(target.saleAmount) : 0,
              );
              datasets.refundData.push(
                target?.refundAmount != null ? Number(target.refundAmount.replaceAll('-', '')) : 0,
              );
              datasets.referralData.push( // 레퍼럴 데이터 추가
                target?.referralAmount != null ? Number(target.referralAmount) : 0,
              );
            }
            const { success, error, warning } = this.$vuetify.theme.themes.light;
            // const { success, error } = this.$vuetify.theme.themes.light;
            this.monthOptionsForm.colors = [success, error, warning];
            this[`${column}Data`][0].data = datasets.saleData;
            this[`${column}Data`][1].data = datasets.refundData;
            this[`${column}Data`][2].data = datasets.referralData;
            this.monthOptionsForm.xaxis.categories = datasets.categories;
            this[`${column}Options`] = { ...this.monthOptionsForm };
          } else {
            this[`${column}Data`][0].data = [];
            this[`${column}Data`][1].data = [];
            this[`${column}Data`][2].data = [];
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getDatas();
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
  .gray--text {
    color: #aaa;
  }
  .dash-board-wrap {
    .top-wrap {
      .notice-board {
        padding: 4px 8px;
        border: 1px solid #eee;
        width: max-content;
        border-radius: 8px;
        .main {
        font-weight: 500;
        font-size: .9rem;
        max-width: 400px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .date {
          font-size: .8rem;
        }
        .creator {
          font-size: .9rem;
        }
        .empty-text {
          font-size: .9rem;
        }
      }
    }
    .chart-row {
      width: 100%;
      & + .chart-row {
        margin-top:20px;
      }
      .chart-box {
        padding: 20px;
        padding-bottom: 0px;
        border-radius: 8px;
        border: 1px solid #eee;
        .chart-title {
          font-weight: 500;
          font-size: 1.1rem;
          display: flex;
          align-items: center;
          margin-bottom: 0px;
        }
        .empty-chart {
          background-color: #f2f2f2;
          border-radius: 8px;
        }
        &.w-100 {
          width: 100%;
        }
        &.w-50 {
          width: calc(50% - 10px);
          & + .chart-box {
            margin-left: 20px;
          }
        }
      }
    }
  }
</style>
